/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { getRealName, getApiUrl, getImageUrl } from 'helpers/helper';

const DocumentContent = (props) => {
	const { t } = useTranslation();
	const params = useParams();
	return (
		<Fragment>
			{props.data.file_upload &&
				props.data.file_upload.map((topic) => (
					<Link
						to={{
							pathname: `/grade/${params.grade}/${params.subject}/${params.chapter}/docs/${topic.name}`,
							state: {
								topic,
							},
						}}
						css={props.linkStyle}
						key={topic.id}
						location={topic.name}
						className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
					>
						<Fragment>
							<div
								className="text-background"
								css={[
									css`
										height: 50px;
										background: #fdfedc;
										padding: 10px;
										width: 100%;
										border-radius: 0.5rem 0.5rem 0 0;
									`,
								]}
							>
								<img
									src={getImageUrl('/assets/graphics/publishers/cherd.png')}
									alt={topic.name}
									css={[
										css`
											height: 100%;
											object-fit: scale-down;
											margin: 0 auto;
										`,
									]}
								/>
							</div>
							<div
								css={[
									css`
										height: 140px;
										position: relative;
										top: -40%;
										-webkit-transform: translate(0, 50%);
										-ms-transform: translate(0, 50%);
										transform: translate(0, 50%);
									`,
								]}
								className="row-span-2"
							>
								<img
									src={props.getIcon(topic.type)}
									alt={topic.name}
									css={[
										css`
											height: 40%;
											width: 40%;
											object-fit: scale-down;
											margin: 0 auto;
										`,
									]}
								/>
							</div>
							<h5
								className="text-base font-bold  w-full text-center text-background topics-title"
								css={[
									css`
										padding: 10px 0;
										color: #fff;
										background: #90cdd1;
										position: absolute;
										bottom: 0;
										border-radius: 0 0 0.5rem 0.5rem;
									`,
								]}
							>
								{t}
								{i18n.language === 'en'
									? getRealName(topic.name)['englishName']
									: getRealName(topic.name)['nepaliName']}
							</h5>
						</Fragment>
					</Link>
				))}
		</Fragment>
	);
};

export default DocumentContent;
