import React from 'react';
import Content from 'components/Content';
import PATHS from 'routes';
import { useTranslation } from 'react-i18next';

const Subject = (props) => {
	const { t } = useTranslation();
	const { match } = props;
	const breadcrumb = [
		{ title: t('Grade'), active: false, link: PATHS.HOME, back: true },
		{
			title: t('Subject'),
			active: false,
			link: `/grade/${match.params.grade}`,
			back: true,
		},
		{
			title: t('Chapter'),
			active: true,
			link: `/grade/${match.params.grade}/${match.params.subject}`,
			back: false,
		},
	];
	return (
		<Content
			breadcrumb={breadcrumb}
			component="chapter"
			gradeId={match.params.grade}
			subjectId={match.params.subject}
		/>
	);
};

export default Subject;
