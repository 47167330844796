/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import tw from 'twin.macro';

import Grades from 'views/HomeView/Components/Grades';
import Subjects from 'views/SubjectView/Components/Subjects';
import Chapters from 'views/ChapterView/Components/Chapters';
import Topics from 'views/TopicView/Components/Topics';
import Videos from 'views/VideoView/Components/Videos';
import Games from 'views/GameView/Components/Games';
import Documents from 'views/DocumentView/Components/Documents';
import Interactives from 'views/InteractiveView/Components/Interactives';
import Breadcrumbs from './Breadcrumbs';

import bg from '../assets/graphics/bg.svg';

const selectComponents = (
	param,
	id,
	gradeId,
	subjectId,
	chapterId,
	docData,
	videoData,
	interactiveData
) => {
	switch (param) {
		case 'grade':
			return <Grades />;
		case 'subject':
			return <Subjects ids={id} />;
		case 'chapter':
			return <Chapters gradeId={gradeId} subjectId={subjectId} />;
		case 'topic':
			return (
				<Topics gradeId={gradeId} subjectId={subjectId} chapterId={chapterId} />
			);
		case 'video':
			return <Videos ids={id} data={videoData} />;
		case 'game':
			return <Games ids={id} />;
		case 'document':
			return <Documents data={docData} />;
		case 'interactive':
			return <Interactives ids={id} data={interactiveData} />;
		default:
			return <Grades />;
	}
};

const Content = (props) => {
	const {
		breadcrumb,
		component,
		id,
		gradeId,
		subjectId,
		chapterId,
		docData,
		videoData,
		interactiveData,
	} = props;
	return (
		<div
			css={css`
				background: url(${bg});
				background-size: 115px;
				position: relative;
				min-height: 90vh;
			`}
		>
			{/* <div
				className="blur-img"
				css={css`
					background: #fcfcfc;
					height: 100%;
					width: 100%;
					position: absolute;
					opacity: 0.5;
				`}
			/> */}
			<div
				css={[tw`container mx-auto pt-12`, `padding-top: 25px; width: 90%;`]}
			>
				<Breadcrumbs data={breadcrumb} />
				{selectComponents(
					component,
					id,
					gradeId,
					subjectId,
					chapterId,
					docData,
					videoData,
					interactiveData
				)}
			</div>
		</div>
	);
};

export default Content;
