import { getEndPoint, getApiToken } from 'config/Config';
import { httpService } from 'services/HttpService';
import OleNepalHttpService from 'services/OleNepalHttpService';

class Subject {
	static getService(path) {
		const completePath = getEndPoint() + path;
		if (getApiToken()) {
			return OleNepalHttpService.get(completePath);
		}
		/**
		 * If no headers is required
		 */
		return httpService.request('get', null, completePath);
	}

	static getSubject(gradeId) {
		const path = `/api/v1/grade/${gradeId}/subject/list`;
		return Subject.getService(path);
	}
}

export default Subject;
