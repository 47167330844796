/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import Modal from 'components/Modal';
import { getRealName, getApiUrl } from 'helpers/helper';
import i18n from 'i18next';

const AudioContent = (props) => {
	const openModal = (e, audioModal) => {
		e.preventDefault();
		document.getElementById(`modal${audioModal}`).style.display = 'block';
	};
	return (
		<Fragment>
			{props.data.file_upload.map((data) => (
				<Fragment key={data.id}>
					<Modal
						modalName={`modal${data.id}`}
						data={data.link}
						topic={data.name}
					/>
					<div
						css={props.linkStyle}
						key={data.id}
						onClick={(e) => openModal(e, data.id)}
						className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
					>
						<div
							className="text-background"
							css={[
								css`
									height: 50px;
									background: #fdfedc;
									padding: 10px;
									width: 100%;
									border-radius: 0.5rem 0.5rem 0 0;
								`,
							]}
						>
							<img
								src={getApiUrl(data.publisher_logo)}
								alt={data.name}
								css={[
									css`
										height: 100%;
										object-fit: scale-down;
										margin: 0 auto;
									`,
								]}
							/>
						</div>
						<div
							css={[
								css`
									height: 140px;
									position: relative;
									top: -40%;
									-webkit-transform: translate(0, 50%);
									-ms-transform: translate(0, 50%);
									transform: translate(0, 50%);
								`,
							]}
							className="row-span-2"
						>
							<img
								src={props.getIcon('audio')}
								alt={data.name}
								css={[
									css`
										height: 40%;
										width: 40%;
										object-fit: scale-down;
										margin: 0 auto;
									`,
								]}
							/>
						</div>
						<h5
							className="text-base font-bold  w-full text-center text-background topics-title"
							css={[
								css`
									padding: 10px 0;
									color: #fff;
									background: #90cdd1;
									position: absolute;
									bottom: 0;
									border-radius: 0 0 0.5rem 0.5rem;
								`,
							]}
						>
							{i18n.language === 'en'
								? getRealName(data.name)['englishName']
								: getRealName(data.name)['nepaliName']}
						</h5>
					</div>
				</Fragment>
			))}
		</Fragment>
	);
};

export default AudioContent;
