import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const GoogleTag = () => {
	useEffect(() => {
		ReactGA.initialize('G-R62C04S0MH');
	}, []);

	useEffect(() => {
		ReactGA.set({ page: window.location.pathname });
		ReactGA.pageview(window.location.pathname + window.location.search);
	});
	return <></>;
};

export default withRouter(GoogleTag);
