/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import { getRelatedChapterRequest, setChapter } from 'actions/chapter';
import { getRelatedChapters } from 'selectors/chapter';
import { getRealName, getApiUrl } from 'helpers/helper';

const Chapter = (props) => {
	const [chapterLoaded, setChapterLoaded] = useState(false);
	const [chapters, setChapters] = useState('');
	const [currentData, setCurrentData] = useState({});
	const [error, setError] = useState('');
	const { t } = useTranslation();
	let param = useParams();

	const chapterLoadSuccess = (data) => {
		setChapters(data.chapters);
		setCurrentData({
			grade: data.grade,
			subject: data.subject,
		});
		props.setChapter(data.chapters);
		setChapterLoaded(true);
	};
	const chapterLoadFail = (e) => {
		setChapterLoaded(false);
		setError(e);
	};

	useEffect(() => {
		props.getRelatedChapterRequest(
			param.grade,
			param.subject,
			chapterLoadSuccess,
			chapterLoadFail
		);
	}, []);

	const linkStyle = [
		css`
			cursor: pointer;
			border-top: 10px solid #eba9a5;
			background: #fff;
			width: 80%;
			height: 180px;
			box-shadow: 0 2px 3px -2px black;
			position: relative;
			&:hover {
				border-top-color: #f2c461;
				& .text-background {
					background: #f2c461;
				}
			}
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				width: 100%;
			}
		`,
	];

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];

	if (error) return <h1>{error}</h1>;
	return chapterLoaded ? (
		<div
			className="flex justify-around"
			css={css`
				padding-top: 25px;
			`}
		>
			<div className="px-1 w-1/2 flex-auto">
				<div className="title uppercase" css={titleStyle}>
					<h3>{t('Select Chapter')}</h3>
					<PageTitle
						data={[
							`${t('Grade')} ${
								i18n.language === 'en'
									? currentData.grade.name_en_number
									: currentData.grade.name_ne_number
							}`,
							`${
								i18n.language === 'en'
									? getRealName(currentData.subject.name)['englishName']
									: getRealName(currentData.subject.name)['nepaliName']
							}`,
						]}
					/>
				</div>
				<div
					className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-y-8 cg-2"
					css={[
						css`
							padding-bottom: 10px;
						`,
					]}
				>
					{chapters.map((chapter) => (
						<Link
							to={`/grade/${param.grade}/${param.subject}/${chapter.slug}`}
							css={linkStyle}
							key={chapter.id}
							title={
								i18n.language === 'en'
									? getRealName(chapter.name)['englishName']
									: getRealName(chapter.name)['nepaliName']
							}
							className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
						>
							<div
								css={[
									css`
										height: 100px;
										position: relative;
										top: -48%;
										transform: translate(0, 50%);
									`,
								]}
								className="row-span-2"
							>
								<img
									src={getApiUrl(chapter.thumbnail)}
									alt={chapter.name}
									css={[
										css`
											height: 100%;
											object-fit: scale-down;
										`,
									]}
								/>
							</div>
							<h5
								className="text-base font-bold  w-full text-center text-background"
								css={[
									css`
										padding: 10px 0;
										color: #385c72;
										background: #fafae2;
										position: absolute;
										bottom: 0;
										border-radius: 0 0 0.5rem 0.5rem;
										max-height: 10vh;
										word-break: break-word;
										overflow-x: hidden;
										overflow-y: auto;
										&::-webkit-scrollbar {
											width: 0;
										}
									`,
								]}
							>
								{i18n.language === 'en' ? chapter.number_en : chapter.number_ne}
								{'. '}
								{i18n.language === 'en'
									? getRealName(chapter.name)['englishName']
									: getRealName(chapter.name)['nepaliName']}
							</h5>
						</Link>
					))}
				</div>
			</div>
		</div>
	) : (
		<p css={[`margin: 20px 0;`]}>Loading Chapters...</p>
	);
};

const mapStateToProps = (state) => {
	return {
		chapters: getRelatedChapters(state),
	};
};

const dispatchProps = { getRelatedChapterRequest, setChapter };
export default connect(mapStateToProps, dispatchProps)(Chapter);
