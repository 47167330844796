import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Subject from 'api/subject';

function* handleGetSubject(action) {
	const { id, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(Subject.getSubject, id);
		if (!res.status) throw new Error(res);
		if (callbackSuccess) callbackSuccess(res.data);
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchGetSubjectRequest() {
	yield takeLatest(allTypes.GET_SUBJECT, handleGetSubject);
}

export default function* subjectSaga() {
	yield all([watchGetSubjectRequest()]);
}
