import React from 'react';
import { getApiUrl } from 'helpers/helper';

const Modal = (props) => {
	const { modalName, data, topic } = props;
	const closeModal = (e, audioModal) => {
		document.getElementById(audioModal).style.display = 'none';
		const audio = document.querySelector(`#audio${audioModal}`);
		audio.pause();
		audio.currentTime = 0;
	};
	return (
		<div id={modalName} className="modal">
			<div className="modal-content">
				<div className="audioContent">
					<span className="close" onClick={(e) => closeModal(e, modalName)}>
						&times;
					</span>
					<p>{topic}</p>
					<audio controls controlsList="nodownload" id={`audio${modalName}`}>
						<source src={getApiUrl(data)} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
				</div>
			</div>
		</div>
	);
};

export default Modal;
