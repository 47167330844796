import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Grade from 'api/grade';

function* handleGetGrades(action) {
	const { callbackSuccess, callbackError } = action;
	try {
		const res = yield call(Grade.getGrades);
		if (!res.status) throw new Error(res);
		if (callbackSuccess) callbackSuccess(res.data);
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchGetGradesRequest() {
	yield takeLatest(allTypes.GET_GRADES, handleGetGrades);
}

export default function* gradeSaga() {
	yield all([watchGetGradesRequest()]);
}
