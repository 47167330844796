/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getRealName, getApiUrl } from 'helpers/helper';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const VideoContent = (props) => {
	const { t } = useTranslation();
	const params = useParams();
	const { data, linkStyle, getIcon } = props;
	console.log(data, '>>');
	return (
		<>
			<Link
				to={{
					pathname: `/grade/${params.grade}/${params.subject}/${params.chapter}/video/${data.id}`,
				}}
				css={linkStyle}
				key={data.id}
				className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
			>
				<>
					<div
						className="text-background"
						css={[
							css`
								height: 50px;
								background: #fdfedc;
								padding: 10px;
								width: 100%;
								border-radius: 0.5rem 0.5rem 0 0;
							`,
						]}
					>
						<img
							src={getApiUrl(data.publisher_logo)}
							alt={data.title}
							css={[
								css`
									height: 100%;
									object-fit: scale-down;
									margin: 0 auto;
								`,
							]}
						/>
					</div>
					<div
						css={[
							css`
								height: 140px;
								position: relative;
								top: -40%;
								-webkit-transform: translate(0, 50%);
								-ms-transform: translate(0, 50%);
								transform: translate(0, 50%);
							`,
						]}
						className="row-span-2"
					>
						<img
							src={getIcon('video')}
							alt={data.title}
							css={[
								css`
									height: 40%;
									width: 40%;
									object-fit: scale-down;
									margin: 0 auto;
								`,
							]}
						/>
					</div>
					<h5
						className="text-base font-bold  w-full text-center text-background topics-title"
						css={[
							css`
								padding: 10px 0;
								color: #fff;
								background: #90cdd1;
								position: absolute;
								bottom: 0;
								border-radius: 0 0 0.5rem 0.5rem;
								word-break: break-word;
								overflow-x: hidden;
								overflow-y: auto;
								max-height: 40px;
								&::-webkit-scrollbar {
									width: 0;
								}
							`,
						]}
					>
						{t}
						{i18n.language === 'en'
							? getRealName(data.title).englishName
							: getRealName(data.title).nepaliName}
					</h5>
				</>
			</Link>
			{/* {props.data[props.data.key_to_use].map((data) => (
				<Link
					to={{
						pathname: `/grade/${params.grade}/${params.subject}/${params.chapter}/video/${data.name}`,
						state: {
							data: data,
							keyToUse: props.data.key_to_use,
						},
					}}
					css={props.linkStyle}
					key={data.id}
					className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
				>
					<>
						<div
							className="text-background"
							css={[
								css`
									height: 50px;
									background: #fdfedc;
									padding: 10px;
									width: 100%;
									border-radius: 0.5rem 0.5rem 0 0;
								`,
							]}
						>
							<img
								src={getApiUrl(data.publisher_logo)}
								alt={data.name}
								css={[
									css`
										height: 100%;
										object-fit: scale-down;
										margin: 0 auto;
									`,
								]}
							/>
						</div>
						<div
							css={[
								css`
									height: 140px;
									position: relative;
									top: -40%;
									-webkit-transform: translate(0, 50%);
									-ms-transform: translate(0, 50%);
									transform: translate(0, 50%);
								`,
							]}
							className="row-span-2"
						>
							<img
								src={props.getIcon('video')}
								alt={data.name}
								css={[
									css`
										height: 40%;
										width: 40%;
										object-fit: scale-down;
										margin: 0 auto;
									`,
								]}
							/>
						</div>
						<h5
							className="text-base font-bold  w-full text-center text-background topics-title"
							css={[
								css`
									padding: 10px 0;
									color: #fff;
									background: #90cdd1;
									position: absolute;
									bottom: 0;
									border-radius: 0 0 0.5rem 0.5rem;
									word-break: break-word;
									overflow-x: hidden;
									overflow-y: auto;
									max-height: 40px;
									&::-webkit-scrollbar {
										width: 0;
									}
								`,
							]}
						>
							{t}
							{i18n.language === 'en'
								? getRealName(data.name).englishName
								: getRealName(data.name).nepaliName}
						</h5>
					</>
				</Link>
			))} */}
		</>
	);
};

export default VideoContent;
