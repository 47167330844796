import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Provider } from 'react-redux';

import PATHS from 'routes';
import { runMiddlewares, getStore } from 'services/ReduxService';

import Navigation from 'components/Navigation';
import Home from 'views/HomeView/Home';
import Subject from 'views/SubjectView/Subject';
import Chapter from 'views/ChapterView/Chapter';
import Topic from 'views/TopicView/Topic';
import Game from 'views/GameView/Game';
import Video from 'views/VideoView/Video';
import Document from 'views/DocumentView/Document';
import MobileEngine from 'components/MobileEngine';
import SearchResult from 'views/SearchView/SearchResult';
import Interactives from 'views/InteractiveView/Interactive';
import GoogleAnalytics from 'components/GoogleAnalytics';
import './assets/styles.css';

const DEFAULT_TITLE = 'E-Pustakalaya: Grade System';

const TRACKING_ID = "334901833"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const OleRoute = (props) => {
	const { title, path, component } = props;
	document.title = title || DEFAULT_TITLE;
	window.scroll(0, 0);
	return <Route path={path} component={component} />;
};

function App() {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		runMiddlewares(() => {
			setIsLoading(false);
		});
	}, []);

	if (isLoading) return <div className="App">Loading...</div>;

	const reduxStore = getStore();

	//useEffect(() => {
	//	ReactGA.pageview(window.location.pathname + window.location.search);
	//  }, []);
	  
	return (
		<Provider store={reduxStore}>
			<Router>
				<Navigation />
				<GoogleAnalytics />
				<Switch>
					<OleRoute exact path={PATHS.HOME} component={Home} />
					<OleRoute
						exact
						path={PATHS.SUBJECT}
						component={Subject}
						title="Choose Subject"
					/>
					<OleRoute
						exact
						path={PATHS.CHAPTER}
						component={Chapter}
						title="Choose Chapter"
					/>
					<OleRoute
						exact
						path={PATHS.TOPIC}
						component={Topic}
						title="Choose Topic"
					/>
					<OleRoute exact path={PATHS.VIDEO} component={Video} />
					<OleRoute exact path={PATHS.GAMES} component={Game} />
					<OleRoute exact path={PATHS.MOBILE_SEARCH} component={MobileEngine} />
					<OleRoute exact path={PATHS.SEARCH} component={SearchResult} />
					<OleRoute exact path={PATHS.DOCS} component={Document} />
					<OleRoute exact path={PATHS.INTERACTIVE} component={Interactives} />
					<OleRoute exact path={PATHS.NOT_FOUND} component={Home} />
				</Switch>
			</Router>
		</Provider>
	);
}

export default App;
