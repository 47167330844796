import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Search from 'api/search';

function* handleSearch(action) {
	const { query, currentPage, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(Search.getSearch, query, currentPage);
		if (!res.status) throw new Error(res);
		if (callbackSuccess) callbackSuccess(res.data);
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchSearch() {
	yield takeLatest(allTypes.GET_SEARCH_DATA, handleSearch);
}

export default function* searchSaga() {
	yield all([watchSearch()]);
}
