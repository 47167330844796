/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import i18n from 'i18next';

import { getGradeRequest } from 'actions/grades';
import { getAllGrades } from 'selectors/grade';
import { getImageUrl } from 'helpers/helper';

const Grades = (props) => {
	const [gradeLoaded, setGradeLoaded] = useState(false);
	const [grades, setGrades] = useState([]);
	const [error, setError] = useState('');
	const { t } = useTranslation();
	const stateData = useSelector((state) => state.grades);

	const blockStyles = [
		css`
			background: linear-gradient(90deg, #eeb6b3 50%, #eba9a5 50%);
			cursor: pointer;
			place-self: center;
			box-shadow: 0 2px 3px -2px black;
			h5 {
				font-family: 'Segoe UI', sans-serif;
				line-height: 2.8rem;
			}

			&:hover {
				background: linear-gradient(90deg, #f4cd79 50%, #f2c461 50%);
			}
		`,
	];

	const gradeLoadSuccess = (data) => {
		setGrades(data.grades);
		setGradeLoaded(true);
	};
	const gradeLoadFail = (e) => {
		let data = stateData.allGrades;
		if (data) {
			setGrades(data);
			setGradeLoaded(true);
		} else {
			setGradeLoaded(false);
			setError(e);
		}
	};

	useEffect(() => {
		props.getGradeRequest(gradeLoadSuccess, gradeLoadFail);
	}, []);

	if (window.location.search) {
		props.history.push({
			pathname: `/search-result/${window.location.search}`,
		});
	}

	if (error) return <h1>{error}</h1>;
	return gradeLoaded ? (
		<div
			className="flex justify-around pt-8 p2"
			css={css`
				position: relative;
			`}
		>
			<div className="flex-auto grade-teacher hidden sm:block">
				<img
					src={getImageUrl('/assets/graphics/character/Grade.svg')}
					alt="teacher"
					css={[
						css`
							height: 75%;
							margin: 0 auto;
							position: fixed;
						`,
					]}
				/>
			</div>
			<div className="px-1 text-center w-1/2 flex-auto">
				<h3 className="title" title={t('Select Your Grade')}>
					{t('Select Your Grade')}
				</h3>
				<div
					className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-y-8 gap-x-8"
					css={[
						css`
							padding-bottom: 10px;
						`,
					]}
				>
					{grades.map((grade) => (
						<Link
							to={`/grade/${grade.slug}`}
							css={blockStyles}
							className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg text-white"
							key={grade.id}
							title={
								i18n.language === 'en'
									? grade.name_en_number
									: grade.name_ne_number
							}
						>
							<p className="text-xl">{t('Grade')}</p>
							<h5 className="text-5xl font-bold">
								{i18n.language === 'en'
									? grade.name_en_number
									: grade.name_ne_number}
							</h5>
						</Link>
					))}
				</div>
			</div>
		</div>
	) : (
		<p css={[`margin: 20px 0;`]}>Loading Grades...</p>
	);
};

const mapStateToProps = (state) => {
	return {
		grades: getAllGrades(state),
	};
};

const dispatchProps = { getGradeRequest };
export default connect(mapStateToProps, dispatchProps)(withRouter(Grades));
