/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import { connect } from 'react-redux';
import 'react-html5video/dist/styles.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Redirect, useParams } from 'react-router-dom';
import { getApiUrl, getRealName } from 'helpers/helper';
import { getAllVideosById } from 'actions/video';
import PageTitle from 'components/PageTitle';
import { getEndPoint } from 'config/Config';

function getId(url) {
	const re = /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
	try {
		const id = url.match(re)[7];
		return id;
	} catch {
		return '';
	}
}

let videoLists = [];
let key_to_use;

const Videos = (props) => {
	document.getElementById('root').style.display = 'unset';
	const params = useParams();
	const { t } = useTranslation();
	const { data } = props;
	const [items, setItems] = useState({ loading: true, embed_links: [] });
	const [videoPlay, setVideoPlay] = useState({
		currentId: '',
		currentUrl: '',
		currentName: '',
	});
	// if (!props.data)
	// 	return (
	// 		<Redirect
	// 			to={`/grade/${params.grade}/${params.subject}/${params.chapter}`}
	// 		/>
	// 	);

	const videoSuccess = (resp) => {
		// setState({
		// 	...state,
		// 	key_to_use: items.key_to_use,
		// 	loading: false,
		// });
		if (resp.items && resp.items.key_to_use) {
			key_to_use = resp.items.key_to_use;
			if (resp.items[resp.items.key_to_use].length > 0) {
				videoLists = resp.items[resp.items.key_to_use];
				setVideoPlay((prevState) => ({
					...prevState,
					currentId: resp.items[resp.items.key_to_use][0].id,
					currentUrl:
						key_to_use === 'file_upload'
							? getEndPoint() + resp.items[resp.items.key_to_use][0].link
							: resp.items[resp.items.key_to_use][0].link,
					currentName: resp.items[resp.items.key_to_use][0].name,
				}));
			}
		}
	};

	const videoFailed = (resp) => {
		console.log(resp, 'error');
		setItems((prevState) => ({
			...prevState,
			loading: false,
		}));
	};

	useEffect(() => {
		props.getAllVideosById(params.id, videoSuccess, videoFailed);
	}, []);

	// useEffect(() => {
	// 	if (state.key_to_use) {
	// 		const keyToUse = state.key_to_use;
	// 		console.log(keyToUse);
	// 		const realData = state[keyToUse];
	// 		const firstVideo = realData[0];
	// 		setVideoPlay({
	// 			...videoPlay,
	// 			data: realData,
	// 			keyToUse: keyToUse,
	// 		});
	// 	}
	// }, [state.items]);

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			z-index: 1000;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];

	const handleChange = (id, link, name) => {
		setVideoPlay({
			...videoPlay,
			currentId: id,
			currentUrl: key_to_use === 'file_upload' ? getEndPoint() + link : link,
			currentName: name,
		});
	};

	return (
		<div
			css={css`
				padding-top: 25px;
			`}
		>
			<h1
				css={[
					css`
						font-size: 1.5rem;
						margin-bottom: 1rem;
					`,
				]}
			>
				{videoPlay.currentName}
			</h1>
			<div className="row">
				<div className="col-md-8">
					{videoPlay.currentUrl && (
						<iframe
							src={`${videoPlay.currentUrl}`}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							css={[
								css`
									margin: 0 auto;
									width: 100%;
									height: 450px;
									z-index: 1000;
									@media only screen and (max-device-width: 480px) {
										width: 100%;
									}
								`,
							]}
						/>
					)}
				</div>
				<div className="col-md-4 video-list">
					{videoLists.map((data) => (
						<div
							className="row-gap"
							onClick={() => handleChange(data.id, data.link, data.name)}
						>
							<div className="img-cont">
								<img
									src={`//img.youtube.com/vi/${getId(data.link)}/sddefault.jpg`}
									className="video-img"
								/>
							</div>
							<div className="video-title">
								{data.name}
								{data.id === videoPlay.currentId && (
									<button className="now-playing">Now Playing</button>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const dispatchProps = { getAllVideosById };
export default connect(mapStateToProps, dispatchProps)(Videos);
