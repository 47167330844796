/** @jsx jsx **/
import { jsx, css } from '@emotion/core';

const PageTitle = (props) => {
	const titleStyle = [
		css`
			padding-left: 2rem;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				padding-left: 0;
			}
		`,
	];
	const { data } = props;
	return (
		data && (
			<div css={titleStyle} className="sub-title">
				({data.join(' > ')})
			</div>
		)
	);
};

export default PageTitle;
