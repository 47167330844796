import * as allTypes from 'actions/types';

const initialState = {};

export const grades = (state = initialState, action) => {
	const { grade } = action;
	switch (action.type) {
		case allTypes.GET_GRADES_UPDATE: {
			return { ...state, allGrades: grade.grades };
		}
		case allTypes.SET_GRADE:
		case allTypes.SET_CURRENT_GRADE: {
			return { ...state, grade: grade };
		}
		default:
			return state;
	}
};
