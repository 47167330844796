/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { getIcon } from 'helpers/helper';
import { useTranslation } from 'react-i18next';
import { getRealName, getApiUrl } from 'helpers/helper';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import AudioContent from './AudioContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getImageUrl } from 'helpers/helper';

const SearchResult = (props) => {
	const { t } = useTranslation();
	const [top, setTop] = useState(false);

	const { searchData, searchResponse, next, newData } = props;
	const linkStyle = [
		css`
			cursor: pointer;
			display: grid;
			grid-template-columns: 100%;
			background: #fff;
			width: 80%;
			height: 100%;
			box-shadow: 0 2px 3px -2px black;
			position: relative;
			&:hover {
				border-top-color: #f2c461;
				& .text-background {
					background: #f2c461;
				}If has link for next page then true else false
			}
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				width: 100%;
			}
		`,
	];
	useEffect(() => {
		window.onscroll = function () {
			scrollFunction();
		};
		function scrollFunction() {
			if (
				document.body.scrollTop > 500 ||
				document.documentElement.scrollTop > 500
			) {
				setTop(true);
			} else {
				setTop(false);
			}
		}
	});
	return (
		<Fragment>
			{searchResponse && !props.desktop && (
				<h3
					css={[
						css`
							font-size: 22px;
							padding: 10px 0;
						`,
					]}
				>
					{t('Search Result')}
				</h3>
			)}
			<div
				css={[
					css`
						padding-bottom: 10px;
					`,
				]}
			>
				{searchData && searchData.length > 0 ? (
					<InfiniteScroll
						dataLength={searchData.length}
						hasMore={next ? true : false}
						next={newData}
						className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-y-8 cg-2"
					>
						{searchData.map((data) => (
							<Fragment key={data.id}>
								{data.type === 'audio' ? (
									<AudioContent
										audioData={data}
										getIcon={getIcon}
										linkStyle={linkStyle}
									/>
								) : (
									<Link
										to={{
											pathname: `/grade/${data.grade_slug}/${
												data.subject_slug
											}/${data.chapter_slug}/${
												data.type === 'video'
													? data.type
													: data.type === 'document'
													? 'docs'
													: 'interactive'
											}/${data.name ? data.name : data.title}`,
											state: {
												data,
											},
										}}
										css={linkStyle}
										key={data.id}
										className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
									>
										<div
											className="text-background"
											css={[
												css`
													height: 50px;
													background: #fdfedc;
													padding: 10px;
													width: 100%;
													border-radius: 0.5rem 0.5rem 0 0;
												`,
											]}
										>
											<img
												src={
													data.type === 'document'
														? getImageUrl(
																'/assets/graphics/publishers/cherd.png'
														  )
														: getApiUrl(data.publisher_logo)
												}
												alt={getRealName(data.grade)['englishName']}
												css={[
													css`
														height: 100%;
														object-fit: scale-down;
														margin: 0 auto;
													`,
												]}
											/>
										</div>
										<div
											css={[
												css`
													height: 100px;
													position: relative;
													top: -40%;
													-webkit-transform: translate(0, 50%);
													-ms-transform: translate(0, 50%);
													transform: translate(0, 50%);
												`,
											]}
											className="row-span-2"
										>
											<img
												src={getIcon(data.type)}
												alt="No Data"
												css={[
													css`
														height: 65%;
														width: 50%;
														object-fit: scale-down;
														margin: 0 auto;
													`,
												]}
											/>
										</div>

										<div
											className="text-base font-bold  w-full text-center text-background topics-title"
											css={[
												css`
													padding: 10px 0;
													color: #fff;
													background: #90cdd1;
													bottom: 0;
													border-radius: 0 0 0.5rem 0.5rem;
												`,
											]}
										>
											<p
												css={[
													css`
														width: 16ch;
														overflow: hidden;
														white-space: nowrap;
														margin: 0 auto;
														height: 20px;
													`,
												]}
											>
												{i18n.language === 'en'
													? getRealName(data.name ? data.name : data.title)[
															'englishName'
													  ]
													: getRealName(data.name ? data.name : data.title)[
															'nepaliName'
													  ]}
											</p>
											<h3
												css={[
													css`
														color: #244a4c;
														font-size: 20px;
													`,
												]}
											>
												{t('Grade')} {t(data.grade)}
											</h3>
											<h5
												css={[
													css`
														width: 16ch;
														overflow: hidden;
														white-space: nowrap;
														margin: 0 auto;
													`,
												]}
											>
												{i18n.language === 'en'
													? getRealName(data.subject)['englishName']
													: getRealName(data.subject)['nepaliName']}
											</h5>
											<h5
												css={[
													css`
														width: 16ch;
														overflow: hidden;
														white-space: nowrap;
														margin: 0 auto;
													`,
												]}
											>
												{i18n.language === 'en'
													? getRealName(data.chapter)['englishName']
													: getRealName(data.chapter)['nepaliName']}
											</h5>
										</div>
									</Link>
								)}
							</Fragment>
						))}
					</InfiniteScroll>
				) : searchResponse ? (
					<p>{t('No Data Found')}</p>
				) : (
					''
				)}
			</div>
			{top && (
				<img
					src={getImageUrl('/assets/graphics/Top.svg')}
					alt="go_to_top"
					title="Go To Top"
					css={[
						css`
							position: fixed;
							bottom: 10px;
							right: 10px;
							height: 8%;
							cursor: pointer;
						`,
					]}
					onClick={() => window.scroll(0, 0)}
				/>
			)}
		</Fragment>
	);
};

export default SearchResult;
