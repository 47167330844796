import React from 'react';
import Content from 'components/Content';
import PATHS from 'routes';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();
	const breadcrumb = [
		{ title: t('Grade'), active: true, link: PATHS.HOME, back: false },
	];
	return <Content breadcrumb={breadcrumb} component="grade" />;
};

export default Home;
