/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

import SearchEngine from './SearchEngine';
import MobileSearchEngine from './MobileSearchEngine';
import logo from '../assets/graphics/logo.svg';

/* color: ${props.active ? '#aeaeae' : '#cbcbcb'}; */
const activeText = css`
	color: #aeaeae;
`;

const LangSelector = styled.button`
	padding: 0px 10px;
	color: #cbcbcb;
	&:focus {
		outline: none;
	}
	&:last-child {
		border-left: 1px solid #aaaaaa;
	}
`;

const Navigation = () => {
	const { i18n } = useTranslation();
	const handleClick = (lang) => {
		i18n.changeLanguage(lang);
	};
	const { t } = useTranslation();

	return (
		<header
			css={[
				tw`w-full bg-white`,
				css`
					border-bottom: 1px solid #00000017;
					position: relative;
					z-index: 5;
				`,
			]}
		>
			<div
				className="container mx-auto flex flex-row md:flex-row items-center justify-between py-6 "
				css={css`
					width: 90%;
					@media only screen and (max-device-width: 320px) {
						width: 96%;
					}
				`}
			>
				<Link to="/">
					<img src={logo} alt="logo" className="logo" />
				</Link>
				<MobileSearchEngine />
				<SearchEngine />
				{/* {window.innerWidth <= 500 ?  : } */}
				<nav tw="md:mt-0">
					<LangSelector
						type="button"
						css={i18n.language === 'en' && activeText}
						onClick={() => handleClick('en')}
					>
						<div className="hidden md:block">English</div>
						<div className="mobile-display">En</div>
					</LangSelector>
					<LangSelector
						type="button"
						css={[css`padding: 0 6px;`, i18n.language === 'ne' && activeText]}
						onClick={() => handleClick('ne')}
					>
						<div className="hidden md:block">Nepali</div>
						<div className="mobile-display">{t(`Ne`)}</div>
					</LangSelector>
				</nav>
			</div>
		</header>
	);
};

export default Navigation;
