import { httpService } from 'services/HttpService';
import { getApiToken } from 'config/Config';

const getMethod = 'get';

class OleNepalHttpServices {
	async GetAuthorizationHeaders() {
		const token = `Token ${getApiToken()}`;
		return {
			Authorization: token,
		};
	}

	async get(path, query = null) {
		const headers = await this.GetAuthorizationHeaders();
		return httpService.request(getMethod, headers, path, query);
	}
}
const OleNepalHttpService = new OleNepalHttpServices();

export default OleNepalHttpService;
