import { getEndPoint, getApiToken } from 'config/Config';
import { httpService } from 'services/HttpService';
import OleNepalHttpService from 'services/OleNepalHttpService';

class Grade {
	static getService(path) {
		const completePath = getEndPoint() + path;
		if (getApiToken()) {
			return OleNepalHttpService.get(completePath);
		}
		/**
		 * If no headers is required
		 */
		return httpService.request('get', null, completePath);
	}

	static getGrades() {
		const path = '/api/v1/grade/list';
		return Grade.getService(path);
	}
}

export default Grade;
