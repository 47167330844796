import * as allTypes from './types';

export function getRelatedTopicRequest(
	gradeId,
	subjectId,
	chapterId,
	callbackSuccess = null,
	callbackError = null
) {
	return {
		type: allTypes.GET_TOPIC,
		gradeId,
		subjectId,
		chapterId,
		callbackSuccess,
		callbackError,
	};
}

export function setTopic(topic) {
	return {
		type: allTypes.SET_TOPIC,
		topic,
	};
}
