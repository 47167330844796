import * as allTypes from './types';

export function getRelatedSubjectsRequest(
	id,
	callbackSuccess = null,
	callbackError = null
) {
	return {
		type: allTypes.GET_SUBJECT,
		id,
		callbackSuccess,
		callbackError,
	};
}

export function getRelatedSubjectUpdate(subject) {
	return {
		type: allTypes.GET_SUBJECT_UPDATE,
		subject,
	};
}

export function setSubject(subject) {
	return {
		type: allTypes.SET_SUBJECT,
		subject,
	};
}
