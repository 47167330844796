/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { getImageUrl } from 'helpers/helper';

const SearchEngine = () => {
	return (
		<div
			className="relative text-gray-600 w-resp"
			css={[
				css`
					display: none;
					@media only screen and (max-device-width: 500px) {
						display: unset;
					}
				`,
			]}
		>
			<Link to={routes.MOBILE_SEARCH} className="icon-holder">
				<span className="icon">
					<img
						src={getImageUrl('/assets/graphics/search-white.svg')}
						alt="Search"
						css={[
							css`
								height: 100%;
								width: 100%;
								margin: 0 auto;
							`,
						]}
					/>
				</span>
			</Link>
		</div>
	);
};

export default SearchEngine;
