import * as allTypes from './types';

export function getAllVideosById(videoId, videoSuccess, videoFailed) {
	return {
		type: allTypes.GET_ALL_VIDEOS,
		videoId,
		videoSuccess,
		videoFailed,
	};
}
