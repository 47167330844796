/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { getRealName, getApiUrl } from 'helpers/helper';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import { Fragment } from 'react';

const AudioContent = (props) => {
	const { t } = useTranslation();
	const openModal = (e, audioModal) => {
		e.preventDefault();
		document.getElementById(`modal${audioModal}`).style.display = 'block';
	};
	let data = props.audioData;
	return (
		<Fragment>
			<Modal modalName={`modal${data.id}`} data={data.link} topic={data.name} />
			<div
				css={props.linkStyle}
				className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
				onClick={(e) => openModal(e, data.id)}
			>
				<div
					className="text-background"
					css={[
						css`
							height: 50px;
							background: #fdfedc;
							padding: 10px;
							width: 100%;
							border-radius: 0.5rem 0.5rem 0 0;
						`,
					]}
				>
					<img
						src={getApiUrl(data.publisher_logo)}
						alt={getRealName(data.grade)['englishName']}
						css={[
							css`
								height: 100%;
								object-fit: scale-down;
								margin: 0 auto;
							`,
						]}
					/>
				</div>
				<div
					css={[
						css`
							height: 100px;
							position: relative;
							top: -40%;
							-webkit-transform: translate(0, 50%);
							-ms-transform: translate(0, 50%);
							transform: translate(0, 50%);
						`,
					]}
					className="row-span-2"
				>
					<img
						src={props.getIcon(data.type)}
						alt="No Data"
						css={[
							css`
								height: 65%;
								width: 50%;
								object-fit: scale-down;
								margin: 0 auto;
							`,
						]}
					/>
				</div>

				<div
					className="text-base font-bold  w-full text-center text-background topics-title"
					css={[
						css`
							padding: 10px 0;
							color: #fff;
							background: #90cdd1;
							bottom: 0;
							border-radius: 0 0 0.5rem 0.5rem;
						`,
					]}
				>
					<p
						css={[
							css`
								width: 16ch;
								overflow: hidden;
								white-space: nowrap;
								margin: 0 auto;
							`,
						]}
					>
						{i18n.language === 'en'
							? getRealName(data.name)['englishName']
							: getRealName(data.name)['nepaliName']}
					</p>
					<h3
						css={[
							css`
								color: #244a4c;
								font-size: 20px;
							`,
						]}
					>
						{t(`Grade ${data.grade}`)}
					</h3>
					<h5>
						{i18n.language === 'en'
							? getRealName(data.subject)['englishName']
							: getRealName(data.subject)['nepaliName']}
					</h5>
					<h5
						css={[
							css`
								width: 16ch;
								overflow: hidden;
								white-space: nowrap;
								margin: 0 auto;
							`,
						]}
					>
						{i18n.language === 'en'
							? getRealName(data.chapter)['englishName']
							: getRealName(data.chapter)['nepaliName']}
					</h5>
				</div>
			</div>
		</Fragment>
	);
};

export default AudioContent;
