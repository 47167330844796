/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

const renderCrumbs = (data) => {
	return (
		<Link
			to={data.link}
			css={[
				tw`text-sm px-3 py-2 rounded-full font-bold `,
				css`
					font-family: 'Segoe UI', sans-serif;
					margin: 0 3px;
					position: relative;
					${window.innerWidth <= 500 && 'font-size: 0.8rem;'}
				`,
				data.active
					? tw`bg-orange-dark text-white`
					: [
							tw`bg-white`,
							'color:#204C62; font-weight: bold;box-shadow: 0 0 4px 6px #f5f5f5;',
					  ],
			]}
			key={data.title}
		>
			{data.back && '< '}
			{data.title}
		</Link>
	);
};

const Breadcrumbs = (props) => {
	const { data } = props;
	return <ul css={tw`flex`}>{data.map((crumbs) => renderCrumbs(crumbs))}</ul>;
};

export default Breadcrumbs;
