const PATHS = {
	HOME: '/',
	SUBJECT: '/grade/:grade',
	CHAPTER: '/grade/:grade/:subject',
	TOPIC: '/grade/:grade/:subject/:chapter',
	GAMES: '/grade/:grade/:subject/:chapter/games/:id',
	DOCS: '/grade/:grade/:subject/:chapter/docs/:id',
	VIDEO: '/grade/:grade/:subject/:chapter/video/:id',
	INTERACTIVE: '/grade/:grade/:subject/:chapter/interactive/:id',
	MOBILE_SEARCH: '/search',
	SEARCH: '/search-result/?q=:query?',
	NOT_FOUND: '*',
};

export default PATHS;
