import * as allTypes from './types';

export function getGradeRequest(callbackSuccess = null, callbackError = null) {
	return {
		type: allTypes.GET_GRADES,
		callbackSuccess,
		callbackError,
	};
}

export function getGradeUpdate(grade) {
	return {
		type: allTypes.GET_GRADES_UPDATE,
		grade,
	};
}

export function setCurrentGrade(grade) {
	return {
		type: allTypes.SET_CURRENT_GRADE,
		grade,
	};
}

export function setGrade(grade) {
	return {
		type: allTypes.SET_GRADE,
		grade,
	};
}
