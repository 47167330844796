import React from 'react';
import Content from 'components/Content';
import PATHS from 'routes';
import { useTranslation } from 'react-i18next';

const Video = (props) => {
	const { t } = useTranslation();
	const { match, location } = props;
	const breadcrumb = [
		{ title: t('Grade'), active: false, link: PATHS.HOME, back: true },
		{
			title: t('Subject'),
			active: false,
			link: `/grade/${match.params.grade}`,
			back: true,
		},
		{
			title: t('Chapter'),
			active: false,
			link: `/grade/${match.params.grade}/${match.params.subject}`,
			back: true,
		},
		{
			title: t('Topic'),
			active: false,
			link: `/grade/${match.params.grade}/${match.params.subject}/${match.params.chapter}`,
			back: true,
		},
	];
	return (
		<Content
			breadcrumb={breadcrumb}
			component="video"
			videoData={location.state}
		/>
	);
};

export default Video;
