/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getRealName } from 'helpers/helper';

const Interactives = (props) => {
	const params = useParams();
	document.getElementById('root').style.display = 'unset';
	const { t } = useTranslation();
	const { data } = props;

	if (!data)
		return (
			<Redirect
				to={`/grade/${params.grade}/${params.subject}/${params.chapter}`}
			/>
		);

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			padding-bottom: 15px;
			z-index: 1000;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];
	const iFrame = [
		css`
			position: relative;
			margin: 0 auto;
			height: 600px;
			width: 70%;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				width: 100%;
			}
		`,
	];
	return (
		<div
			className="flex justify-around"
			css={css`
				padding-top: 25px;
			`}
		>
			<div className="px-1 w-1/2 flex-auto">
				<div className="title uppercase" css={titleStyle}>
					<h3>
						{i18n.language === 'en'
							? getRealName(data.data.title)['englishName']
							: getRealName(data.data.title)['nepaliName']}
					</h3>
				</div>
				<div
					css={css`
						padding-bottom: 15px;
					`}
				>
					{t}
					<iframe
						css={iFrame}
						title={
							i18n.language === 'en'
								? getRealName(data.data.title)['englishName']
								: getRealName(data.data.title)['nepaliName']
						}
						src={data.data.online_domain + data.data.link_to_content}
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default Interactives;
