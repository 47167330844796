/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRelatedTopicRequest, setTopic } from 'actions/topics';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import PageTitle from 'components/PageTitle';
import { getRealName, getIcon } from 'helpers/helper';

import VideoContent from './VideoContent';
import AudioContent from './AudioContent';
import DocumentContent from './DocumentContent';
import InteractiveContent from './InteractiveContent';

const Topics = (props) => {
	const [topicLoaded, setTopicLoaded] = useState(false);
	const [currentData, setCurrentData] = useState({});
	const [error, setError] = useState('');
	const [topics, setTopics] = useState('');
	const { t } = useTranslation();
	const param = useParams();
	const topicLoadSuccess = (data) => {
		setTopics(data.items);
		setCurrentData({
			grade: data.grade,
			subject: data.subject,
			chapter: data.chapter,
		});
		props.setTopic(data);
		setTopicLoaded(true);
	};
	const topicLoadFail = (e) => {
		setTopicLoaded(false);
		setError(e);
	};

	const linkStyle = [
		css`
			cursor: pointer;
			display: grid;
			grid-template-columns: 100%;
			background: #fff;
			width: 80%;
			height: 100%;
			min-height: 165px;
			box-shadow: 0 2px 3px -2px black;
			position: relative;
			&:hover {
				border-top-color: #f2c461;
				& .text-background {
					background: #f2c461;
				}
			}
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				width: 100%;
			}
		`,
	];

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];

	useEffect(() => {
		props.getRelatedTopicRequest(
			param.grade,
			param.subject,
			param.chapter,
			topicLoadSuccess,
			topicLoadFail
		);
	}, []);

	if (error) return <h1>{error}</h1>;
	return topicLoaded ? (
		<div
			className="flex justify-around"
			css={css`
				padding-top: 25px;
			`}
		>
			<div className="px-1 w-1/2 flex-auto">
				<div className="title uppercase" css={titleStyle}>
					<h3>{t('Select Topic')}</h3>
					<PageTitle
						data={[
							`${t('Grade')} ${
								i18n.language === 'en'
									? currentData.grade.name_en_number
									: currentData.grade.name_ne_number
							}`,
							`${
								i18n.language === 'en'
									? getRealName(currentData.subject.name).englishName
									: getRealName(currentData.subject.name).nepaliName
							}`,
							`${
								i18n.language === 'en'
									? getRealName(currentData.chapter.name).englishName
									: getRealName(currentData.chapter.name).nepaliName
							}`,
						]}
					/>
				</div>
				<div
					className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-y-8 cg-2"
					css={[
						css`
							padding-bottom: 10px;
						`,
					]}
				>
					{topics.map((topic) => (
						<Fragment key={`topics${topic.id}`}>
							{topic.type === 'video' ? (
								<VideoContent
									data={topic}
									linkStyle={linkStyle}
									getIcon={getIcon}
								/>
							) : topic.type === 'audio' ? (
								<AudioContent
									data={topic}
									linkStyle={linkStyle}
									getIcon={getIcon}
								/>
							) : topic.type === 'interactive' ? (
								<InteractiveContent
									data={topic}
									linkStyle={linkStyle}
									getIcon={getIcon}
								/>
							) : (
								<DocumentContent
									data={topic}
									linkStyle={linkStyle}
									getIcon={getIcon}
								/>
							)}
						</Fragment>
					))}
				</div>
			</div>
		</div>
	) : (
		<p css={[`margin: 20px 0;`]}>Loading Topics...</p>
	);
};
const mapStateToProps = () => {
	return {};
};
const dispatchProps = { getRelatedTopicRequest, setTopic };

export default connect(mapStateToProps, dispatchProps)(Topics);
