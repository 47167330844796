import { getEndPoint, getApiToken } from 'config/Config';
import { httpService } from 'services/HttpService';
import OleNepalHttpService from 'services/OleNepalHttpService';

class Search {
	static getService(path) {
		const completePath = getEndPoint() + path;
		if (getApiToken()) {
			return OleNepalHttpService.get(completePath);
		}
		/**
		 * If no headers is required
		 */
		return httpService.request('get', null, completePath);
	}

	static getSearch(query, currentPage) {
		if (query) {
			const querySearch =
				currentPage > 1 ? `?q=${query}&page=${currentPage}` : `?q=${query}`;
			const path = `/api/v1/gradewise/search/${querySearch}`;
			return Search.getService(path);
		}
		return null;
	}
}

export default Search;
