import { getEndPoint, getApiToken } from 'config/Config';
import { httpService } from 'services/HttpService';
import OleNepalHttpService from 'services/OleNepalHttpService';

class Topic {
	static getService(path) {
		const completePath = getEndPoint() + path;
		if (getApiToken()) {
			return OleNepalHttpService.get(completePath);
		}
		/**
		 * If no headers is required
		 */
		return httpService.request('get', null, completePath);
	}

	static getTopic(gradeId, subjectId, chapterId) {
		const path = `/api/v1/grade/${gradeId}/subject/${subjectId}/chapter/${chapterId}/`;
		return Topic.getService(path);
	}
}

export default Topic;
