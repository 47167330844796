import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Chapter from 'api/chapter';

function* handleGetChapter(action) {
	const { gradeId, subjectId, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(Chapter.getChapter, gradeId, subjectId);
		if (!res.status) throw new Error(res);
		if (callbackSuccess) callbackSuccess(res.data);
	} catch (e) {
		if (callbackError) {
			callbackError(e.message);
		}
	}
}

function* watchGetChapterRequest() {
	yield takeLatest(allTypes.GET_CHAPTER, handleGetChapter);
}

export default function* chapterSaga() {
	yield all([watchGetChapterRequest()]);
}
