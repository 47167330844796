/** @jsx jsx **/
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';

const Games = () => {
	const [topicLoaded, setTopicLoaded] = useState(false);

	const topicLoadSuccess = () => {
		setTopicLoaded(true);
	};

	useEffect(() => {
		setTimeout(() => {
			topicLoadSuccess();
		}, 200);
	}, []);

	return topicLoaded ? (
		<div className="flex justify-around pt-8">
			<div className="px-1 w-1/2 flex-auto">
				<div className="flex title uppercase">
					<h3>This is the title of the game.</h3>
				</div>
				<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-y-8 cg-2"></div>
			</div>
		</div>
	) : (
		<p>Loading Game...</p>
	);
};

export default Games;
