export const GET_GRADES = 'GET_GRADES';
export const GET_GRADES_UPDATE = 'GET_GRADES_UPDATE';
export const GET_SUBJECT = 'GET_SUBJECT';
export const GET_CHAPTER = 'GET_CHAPTER';
export const GET_SUBJECT_UPDATE = 'GET_SUBJECT_UPDATE';
export const GET_CHAPTER_UPDATE = 'GET_CHAPTER_UPDATE';

export const SET_CURRENT_GRADE = 'SET_CURRENT_GRADE';
export const SET_CURRENT_SUBJECT = 'SET_CURRENT_SUBJECT';
export const SET_CURRENT_CHAPTER = 'SET_CURRENT_CHAPTER';

export const SET_SUBJECT = 'SET_SUBJECT';
export const SET_CHAPTER = 'SET_CHAPTER';

export const GET_TOPIC = 'GET_TOPIC';
export const SET_TOPIC = 'SET_TOPIC';

export const SET_GRADE = 'SET_TOPIC';
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';

export const GET_ALL_VIDEOS = 'GET_ALL_VIDEOS';
