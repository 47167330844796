/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { removeExtraSpace } from 'helpers/helper';

const SearchEngine = (props) => {
	const [input, setInput] = useState('');
	const { t } = useTranslation();

	const handleSubmit = (e) => {
		e.preventDefault();
		if (removeExtraSpace(input)) {
			props.history.push({
				pathname: `/search-result/?q=${removeExtraSpace(input)}`,
			});
		}
	};

	const handleChange = (e) => {
		let value = removeExtraSpace(e.target.value);
		setInput(value);

		// Must be atleast 3 character long and should not be space
		if (value.length > 3 && e.keyCode !== 32) {
			props.history.push({
				pathname: `/search-result/?q=${removeExtraSpace(value)}`,
			});
		}
	};
	const formCss = [
		css`
			width: 33.33%;
			display: none;
			@media only screen and (min-device-width: 501px) {
				display: unset;
			}
			@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
				width: 40%;
			}
		`,
	];
	return (
		<form
			onSubmit={(e) => handleSubmit(e)}
			className="mt-2 md:mt-0"
			css={formCss}
		>
			<div className="relative text-gray-600">
				<input
					list="browsers"
					name="browser"
					id="browser"
					autoComplete="off"
					placeholder={t('Search Grade-Wise Learning')}
					onKeyUp={(e) => handleChange(e)}
					type="search"
					className="bg-gray-200 focus:outline-none h-10 pr-10 sm:pr-16 px-5 rounded-full text-sm w-full"
				/>
				<button
					type="submit"
					name="submit"
					className="absolute bg-blue-mid flex focus:outline-none h-10 items-center justify-center right-0 rounded-r-full text-white top-0 
					w-2/12"
					css={css`
						width: 2.5rem;
						@media (min-width: 640px) {
							width: 4rem;
						}
					`}
				>
					<svg
						className="h-4 w-4 fill-current"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
						version="1.1"
						id="Capa_1"
						x="0px"
						y="0px"
						viewBox="0 0 56.966 56.966"
						style={{ enableBackground: 'new 0 0 56.966 56.966' }}
						xmlSpace="preserve"
						width="512px"
						height="512px"
					>
						<path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
					</svg>
				</button>
			</div>
		</form>
	);
};

export default withRouter(SearchEngine);
