import React from 'react';
import { connect } from 'react-redux';
import Content from 'components/Content';
import PATHS from 'routes';
import { setCurrentGrade } from 'actions/grades';
import { useTranslation } from 'react-i18next';
import { getAllGrades } from 'selectors/grade';

const Subject = (props) => {
	const { match } = props;
	const { t } = useTranslation();
	const breadcrumb = [
		{ title: t('Grade'), active: false, link: PATHS.HOME, back: true },
		{
			title: t('Subject'),
			active: true,
			link: `/grade/${match.params.grade}`,
			back: false,
		},
	];
	return <Content breadcrumb={breadcrumb} component="subject" />;
};

const mapStateToProps = (state) => {
	return {
		grades: getAllGrades(state),
	};
};
const dispatchProps = { setCurrentGrade };
export default connect(mapStateToProps, dispatchProps)(Subject);
