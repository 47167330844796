import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Video from 'api/video';

function* handleGetAllVideo(action) {
	const { videoId, videoSuccess, videoFailed } = action;
	try {
		const res = yield call(Video.getVideo, videoId);
		if (!res.status) throw new Error(res);
		if (videoSuccess) videoSuccess(res.data);
	} catch (e) {
		if (videoFailed) {
			videoFailed(e.message);
		}
	}
}

function* watchGetAllVideoRequest() {
	yield takeLatest(allTypes.GET_ALL_VIDEOS, handleGetAllVideo);
}

export default function* videoSaga() {
	yield all([watchGetAllVideoRequest()]);
}
