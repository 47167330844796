/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import i18n from 'i18next';
import { getRealName, getApiUrl } from 'helpers/helper';

const InteractiveContent = (props) => {
	const params = useParams();
	const { data, getIcon } = props;
	return (
		<Fragment>
			<Link
				to={{
				//	pathname: `/grade/${params.grade}/${params.subject}/${params.chapter}/interactive/${data.title}`,
					pathname: `${data.online_domain + data.link_to_content}`,
					state: {
						data: data,
					},
				}}
				css={props.linkStyle}
				key={data.id}
				className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
				target="_blank"
			>
				<Fragment>
					<div
						className="text-background"
						css={[
							css`
								height: 50px;
								background: #fdfedc;
								padding: 10px;
								width: 100%;
								border-radius: 0.5rem 0.5rem 0 0;
							`,
						]}
					>
						<img
							src={getApiUrl(data.publisher_logo)}
							alt={data.title}
							css={[
								css`
									height: 100%;
									object-fit: scale-down;
									margin: 0 auto;
								`,
							]}
						/>
					</div>
					<div
						css={[
							css`
								height: 140px;
								position: relative;
								top: -40%;
								-webkit-transform: translate(0, 50%);
								-ms-transform: translate(0, 50%);
								transform: translate(0, 50%);
							`,
						]}
						className="row-span-2"
					>
						<img
							src={getIcon('interactive')}
							alt={data.title}
							css={[
								css`
									height: 40%;
									width: 40%;
									object-fit: scale-down;
									margin: 0 auto;
								`,
							]}
						/>
					</div>
					<h5
						className="text-base font-bold  w-full text-center text-background topics-title"
						css={[
							css`
								padding: 10px 0;
								color: #fff;
								background: #90cdd1;
								position: absolute;
								bottom: 0;
								border-radius: 0 0 0.5rem 0.5rem;
								word-break: break-word;
								overflow-x: hidden;
								overflow-y: auto;
								max-height: 40px;
								&::-webkit-scrollbar {
									width: 0;
								}
							`,
						]}
					>
						{i18n.language === 'en'
							? getRealName(data.title)['englishName']
							: getRealName(data.title)['nepaliName']}
					</h5>
				</Fragment>
			</Link>
		</Fragment>
	);
};

export default InteractiveContent;
