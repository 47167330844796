/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { getImageUrl, removeExtraSpace } from 'helpers/helper';
import SearchContent from 'views/SearchView/Components/SearchContent';
import { getSearchData } from 'actions/search';
import { connect } from 'react-redux';

const inputStyle = [
	css`
		height: 3rem;
		background: #f6f4f4;
		width: 95%;
		position: absolute;
		color: #385c72;
		font-size: 20px;
		padding: 0 55px;
		outline: none;
	`,
];

const MobileEngine = (props) => {
	document.getElementById('root').style.display = 'none';
	const initialValue = [];
	const [data, setData] = useState(initialValue);
	const [input, setInput] = useState('');
	const [state, setState] = useState({
		loading: false,
		searchResponse: false,
		count: 0,
		next: '',
		previous: '',
		currentPage: 1,
		infinite: false,
	});

	/**
	 * If search failed due to no internet or server error
	 * ---- State ----
	 * Loading is completed so it will be true
	 * Search response is true, because if API is hit and data not found, being true it will display
	 * "No Data Found"
	 */
	const searchLoadFailed = () => {
		setState({ ...state, loading: false, searchResponse: true });
		setData([]);
	};

	const infiniteLoadFail = () => {
		setState({
			...state,
			loading: false,
			searchResponse: true,
			infinite: false,
		});
		setData([]);
	};

	const loadData = (responseData) => {
		// Slicing current page url to get current page of pagination
		const current = parseInt(responseData.current.slice(-1));
		setState({
			...state,
			loading: false,
			searchResponse: true,
			count: responseData.count,
			next: responseData.next,
			previous: responseData.previous,
			currentPage: current,
			infinite: false,
		});
	};

	const searchLoadSuccess = (responseData) => {
		setData(responseData.results);
		loadData(responseData);
	};

	const infiniteLoadSuccess = (responseData) => {
		setData([...data, ...responseData.results]);
		loadData(responseData);
	};

	/**
	 * When form is submit
	 * --- State ---
	 * Since, API is on the call, loading will be true
	 * Search response will be false since data is not arrived.
	 */
	const handleSubmit = (e) => {
		e.preventDefault();
		setData(initialValue);
		setState({
			...state,
			loading: true,
			searchResponse: false,
			infinite: false,
		});
		props.getSearchData(
			removeExtraSpace(input),
			1,
			searchLoadSuccess,
			searchLoadFailed
		);
	};

	/**
	 * This function activates when user presses back button.
	 */
	function getBack() {
		document.getElementById('root').style.display = 'unset';
		props.history.goBack();
	}

	/**
	 * Function to bind the state and calling API when user type 3 letters.
	 * ---- State ---
	 * Since, API is on the call, loading will be true
	 * Search response will be false since data is not arrived.
	 */
	const handleChange = (e) => {
		setInput(removeExtraSpace(e.target.value));
	};

	/**
	 * Use effect is used since we need to call API when current page changes.
	 */
	useEffect(() => {
		if (input) {
			setState({
				...state,
				loading: true,
				searchResponse: false,
				infinite: false,
			});
			props.getSearchData(
				removeExtraSpace(input),
				state.currentPage,
				infiniteLoadSuccess,
				infiniteLoadFail
			);
		}
	}, [state.infinite === true]);

	/**
	 * This function will increase current page by 1, triggering useEffect.
	 * This function is triggering from SearchContent page within InfiniteScroll.
	 */
	const newPage = () => {
		setState({ currentPage: state.currentPage + 1, infinite: true });
	};

	/**
	 * Using ReactDOM use are creating another main root div for out mobile based search design.
	 * Since, Navigation bar is similar in all pages, we have used Navigation Component in main app.
	 * So this DOM will be created on top of root dom of react. i.e.: <div id="root"></div>
	 */
	return ReactDOM.createPortal(
		<div className="relative text-gray-600 w-full">
			<div
				className="input-container"
				css={css`
					height: 65px;
					padding-right: 10px;
					padding-left: 10px;
				`}
			>
				<form onSubmit={(e) => handleSubmit(e)} className="w-1/3 mt-2 md:mt-0">
					<input
						type="text"
						list="browsers"
						name="browser"
						id="browser"
						css={inputStyle}
						placeholder="Search"
						autoComplete="off"
						onKeyUp={(e) => handleChange(e)}
						autoFocus
					/>
				</form>
				<div
					onClick={getBack}
					className="right"
					css={css`
						color: #385c72;
						position: relative;
						float: left;
						font-weight: bold;
						font-size: 35px;
						padding: 0 15px;
					`}
				>
					{`<`}
				</div>
				<div
					className="icon"
					css={css`
						color: #c3bebe;
						position: relative;
						font-size: 30px;
						float: right;
						height: 50px;
						width: 55px;
						padding: 0 15px;
					`}
				>
					<img
						src={getImageUrl('/assets/graphics/search.svg')}
						alt="Search"
						css={[
							css`
								height: 100%;
								width: 100%;
								margin: 0 auto;
							`,
						]}
					/>
				</div>
			</div>
			<div
				css={[
					css`
						padding-left: 10px;
						padding-right: 10px;
						padding-bottom: 10px;
					`,
				]}
			>
				<SearchContent
					searchData={data}
					desktop={false}
					next={state.next}
					searchResponse={state.searchResponse}
					currentPage={state.currentPage}
					newData={newPage}
				/>
			</div>

			{state.loading && (
				<div
					css={[
						css`
							text-align: center;
							font-size: 18px;
						`,
					]}
				>
					Loading...
				</div>
			)}
		</div>,
		document.getElementById('mobile-search-engine')
	);
};

const mapStateToProps = (state) => {
	return {};
};

const dispatchProps = { getSearchData };
export default connect(mapStateToProps, dispatchProps)(MobileEngine);
