/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiUrl, getRealName } from 'helpers/helper';

const Documents = (props) => {
	const params = useParams();
	document.getElementById('root').style.display = 'unset';
	const { t } = useTranslation();
	if (!props.data)
		return (
			<Redirect
				to={`/grade/${params.grade}/${params.subject}/${params.chapter}`}
			/>
		);

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			padding-bottom: 15px;
			z-index: 1000;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];
	const iFrame = [
		css`
			position: relative;
			margin: 0 auto;
			height: 600px;
			width: 70%;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				width: 100%;
			}
		`,
	];

	return (
		<div
			className="flex justify-around"
			css={css`
				padding-top: 25px;
			`}
		>
			<div className="px-1 w-1/2 flex-auto">
				<div className="title uppercase" css={titleStyle}>
					{props.data.topic ? (
						<h3>
							{i18n.language === 'en'
								? getRealName(props.data.topic.name)['englishName']
								: getRealName(props.data.topic.name)['nepaliName']}
						</h3>
					) : (
						<h3>
							{i18n.language === 'en'
								? getRealName(props.data.data.name)['englishName']
								: getRealName(props.data.data.name)['nepaliName']}
						</h3>
					)}
				</div>
				<div
					css={css`
						padding-bottom: 15px;
					`}
				>
					{t}
					<iframe
						css={iFrame}
						title={
							props.data.topic
								? i18n.language === 'en'
									? getRealName(props.data.topic.name)['englishName']
									: getRealName(props.data.topic.name)['nepaliName']
								: i18n.language === 'en'
								? getRealName(props.data.data.name)['englishName']
								: getRealName(props.data.data.name)['nepaliName']
						}
						src={getApiUrl(
							props.data.topic ? props.data.topic.link : props.data.data.link
						)}
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default Documents;
