import * as allTypes from './types';

export function getRelatedChapterRequest(
	gradeId,
	subjectId,
	callbackSuccess = null,
	callbackError = null
) {
	return {
		type: allTypes.GET_CHAPTER,
		gradeId,
		subjectId,
		callbackSuccess,
		callbackError,
	};
}

export function getRelatedChapterUpdate(chapter) {
	return {
		type: allTypes.GET_CHAPTER_UPDATE,
		chapter,
	};
}

export function setChapter(chapter) {
	return {
		type: allTypes.SET_CHAPTER,
		chapter,
	};
}
