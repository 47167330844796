/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';

import { getRelatedSubjectsRequest, setSubject } from 'actions/subjects';
import PageTitle from 'components/PageTitle';

import { getImageUrl, getRealName, getApiUrl } from 'helpers/helper';

const Subject = (props) => {
	const [subjectLoaded, setSubjectLoaded] = useState(false);
	const [currentGrade, setCurrentGrade] = useState({});
	const [subjects, setSubjects] = useState('');
	const [error, setError] = useState('');
	const { t } = useTranslation();
	let { grade } = useParams();

	const subjectLoadSuccess = (data) => {
		props.setSubject(data);
		setSubjects(data.subjects);
		setCurrentGrade(data.grade);
		setSubjectLoaded(true);
	};

	const subjectLoadFail = (e) => {
		setSubjectLoaded(false);
		setError('Data Not Found.');
	};

	useEffect(() => {
		props.getRelatedSubjectsRequest(grade, subjectLoadSuccess, subjectLoadFail);
	}, []);

	const linkStyle = [
		css`
			cursor: progress;
			background: #7fcfd2;
			height: 100px;
			box-shadow: 0 2px 3px -2px black;
			width: 100px;
			border-radius: 50%;
			padding: 25px;
			margin: 0 auto;
			&:hover {
				background: #f2c461;
				cursor: pointer;
			}
		`,
	];

	if (error) return <h1>{error}</h1>;
	return subjectLoaded ? (
		subjectLoaded && subjects.length > 0 ? (
			<div
				className="flex justify-around"
				css={css`
					padding-top: 25px;
					position: relative;
				`}
			>
				<div className="px-1 w-1/2 flex-auto">
					<div className="flex title uppercase">
						<h3
							css={[
								css`
									margin-right: 5px;
								`,
							]}
						>
							{t('Select Subject')}
						</h3>
						<PageTitle
							data={[
								`${t('Grade')} ${
									i18n.language === 'en'
										? currentGrade.name_en_number
										: currentGrade.name_ne_number
								}`,
							]}
						/>
					</div>
					<div
						className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-y-8 gap-x-8"
						css={[
							css`
								padding-bottom: 10px;
							`,
						]}
					>
						{subjects.map((subject) => (
							<div
								key={subject.id}
								css={[
									css`
										text-align: left;
									`,
								]}
							>
								<Link
									to={`/grade/${grade}/${subject.slug}`}
									css={linkStyle}
									className="w-24 h-24 xl:w-32 xl:h-32 flex items-center justify-center flex-col rounded-lg"
								>
									<img
										src={getApiUrl(subject.thumbnail)}
										alt={getRealName(subject.name)['englishName']}
										css={css`
											height: 100%;
											width: 100%;
											object-fit: scale-down;
											margin: 0 auto;
										`}
									/>
								</Link>
								<h5
									className="text-base font-bold text-center"
									css={[
										css`
											color: #a5a5a5;
											padding-top: 12px;
											max-height: 40px;
											overflow-y: scroll;
											overflow-x: hidden;
											&::-webkit-scrollbar {
												width: 0;
											}
										`,
									]}
								>
									{i18n.language === 'en'
										? getRealName(subject.name)['englishName']
										: getRealName(subject.name)['nepaliName']}
								</h5>
							</div>
						))}
					</div>
				</div>
				<div
					css={[
						css`
							height: 500px;
						`,
					]}
					className="flex-auto hidden sm:block"
				>
					<img
						src={getImageUrl('/assets/graphics/character/Subject.svg')}
						alt="teacher"
						css={[
							css`
								height: 75%;
								margin: 0 auto;
								position: fixed;
							`,
						]}
						className="hidden sm:block"
					/>
				</div>
			</div>
		) : (
			<h1
				css={[
					css`
						text-align: center;
						font-size: 25px;
					`,
				]}
			>
				No Data Found
			</h1>
		)
	) : (
		<p css={[`margin: 20px 0;`]}>Loading Subjects...</p>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const dispatchProps = { getRelatedSubjectsRequest, setSubject };
export default connect(mapStateToProps, dispatchProps)(Subject);
