import * as allTypes from 'actions/types';
import { takeLatest, call, all } from 'redux-saga/effects';
import Topic from 'api/topic';

function* handleGetTopic(action) {
	const {
		gradeId,
		subjectId,
		chapterId,
		callbackSuccess,
		callbackError,
	} = action;
	try {
		const res = yield call(Topic.getTopic, gradeId, subjectId, chapterId);
		if (!res.status) throw new Error(res);
		if (callbackSuccess) callbackSuccess(res.data);
	} catch (e) {
		if (callbackError) {
			callbackError(e.message);
		}
	}
}

function* watchGetTopicRequest() {
	yield takeLatest(allTypes.GET_TOPIC, handleGetTopic);
}

export default function* topicSaga() {
	yield all([watchGetTopicRequest()]);
}
