import React from 'react';
import Content from 'components/Content';
import PATHS from 'routes';

const Game = (props) => {
	const { match } = props;
	const breadcrumb = [
		{ title: 'Grades', active: false, link: PATHS.HOME, back: true },
		{
			title: 'Subjects',
			active: false,
			link: `/${match.params.grade}`,
			back: true,
		},
		{
			title: 'Chapter',
			active: false,
			link: `/${match.params.grade}/${match.params.subject}`,
			back: true,
		},
		{
			title: 'Topic',
			active: false,
			link: `/${match.params.grade}/${match.params.subject}/${match.params.chapter}`,
			back: true,
		},
	];
	return <Content breadcrumb={breadcrumb} component="game" id={match.params} />;
};

export default Game;
