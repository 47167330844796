import { combineReducers } from 'redux';
import { grades } from './grade';
import { subjects } from './subject';
import { chapters } from './chapters';
import { topics } from './topic';

const rootReducer = combineReducers({ grades, subjects, chapters, topics });

const appReducer = (state, action) => {
	return rootReducer(state, action);
};

export default appReducer;
