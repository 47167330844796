export const getImageUrl = (uri) => {
	return process.env.PUBLIC_URL + uri;
};

export const getApiUrl = (uri) => {
	return process.env.REACT_APP_API_URL + uri;
};

export const getRealName = (name) => {
	const englishName = name.split('[[')[0];
	const nepaliName = name.substring(
		name.lastIndexOf('[') + 1,
		name.lastIndexOf(']]')
	);
	return {
		englishName: englishName,
		nepaliName: nepaliName || englishName,
	};
};

export const getIcon = (dataType) => {
	switch (dataType) {
		case 'video':
			return getImageUrl('/assets/graphics/topic/Video.svg');
		case 'document':
			return getImageUrl('/assets/graphics/topic/Reading.svg');
		case 'audio':
			return getImageUrl('/assets/graphics/topic/Audio.svg');
		case 'interactive':
			return getImageUrl('/assets/graphics/topic/Interactive.svg');
		default:
			return getImageUrl('/assets/graphics/topic/Reading.svg');
	}
};

// Remove all extra white space
export const removeExtraSpace = (value) => value.trim().split(/ +/).join(' ');
