/** @jsx jsx **/
import { jsx, css } from '@emotion/core';
import tw from 'twin.macro';
import { getImageUrl } from 'helpers/helper';
import { useTranslation } from 'react-i18next';
import SearchContent from './Components/SearchContent';
import { connect } from 'react-redux';
import { getSearchData } from 'actions/search';
import { useEffect, useState } from 'react';

const SearchResult = (props) => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [state, setState] = useState({
		loading: false,
		searchResponse: false,
		count: 0,
		next: '',
		previous: '',
		currentPage: 1,
		infinite: false,
	});

	const loadData = (responseData) => {
		// Slicing current page url to get current page of pagination
		let current = parseInt(responseData.current.slice(-1));
		setState({
			...state,
			loading: false,
			searchResponse: true,
			count: responseData.count,
			next: responseData.next,
			previous: responseData.previous,
			currentPage: current,
			infinite: false,
		});
	};

	const searchLoadSuccess = (responseData) => {
		setData(responseData.results);
		loadData(responseData);
	};

	const infiniteLoadSuccess = (responseData) => {
		setData([...data, ...responseData.results]);
		loadData(responseData);
	};

	/**
	 * If search failed due to no internet or server error
	 * ---- State ----
	 * Loading is completed so it will be true
	 * Search response is true, because if API is hit and data not found, being true it will display
	 * "No Data Found"
	 */
	const searchLoadFailed = () => {
		setState({ ...state, loading: false, searchResponse: true });
		setData([]);
	};

	const infiniteLoadFail = () => {
		setState({
			...state,
			loading: false,
			searchResponse: true,
			infinite: false,
		});
		setData([]);
	};

	/**
	 * Is called when current page changed using infinite scroll
	 * Or
	 * change is query parameter
	 */
	useEffect(() => {
		setData([]);
		setState({ ...state, loading: true, searchResponse: false });
		props.getSearchData(
			props.match.params.query,
			1,
			searchLoadSuccess,
			searchLoadFailed
		);
	}, [props.match.params.query]);

	useEffect(() => {
		setState({
			...state,
			loading: true,
			searchResponse: false,
			infinite: false,
		});
		props.getSearchData(
			props.match.params.query,
			state.currentPage,
			infiniteLoadSuccess,
			infiniteLoadFail
		);
	}, [state.infinite === true]);

	const titleStyle = [
		css`
			display: flex;
			position: relative;
			@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
				display: block;
			}
		`,
	];

	/**
	 * This function will increase current page by 1, triggering useEffect.
	 * This function is triggering from SearchContent page within InfiniteScroll.
	 */
	const newPage = () => {
		setState({ currentPage: state.currentPage + 1, infinite: true });
	};
	return (
		<div
			css={css`
				background: url(${getImageUrl('/assets/graphics/bg.svg')});
				background-size: 115px;
			`}
		>
			<div
				className="blur-img"
				css={css`
					background: #fcfcfc;
					height: 100%;
					width: 100%;
					position: absolute;
					opacity: 0.5;
				`}
			></div>
			<div
				css={[tw`container mx-auto pt-12`, `padding-top: 25px; width: 95%;`]}
			>
				<div className="flex justify-around">
					<div className="px-1 w-1/2 flex-auto">
						<div className="title uppercase" css={titleStyle}>
							<h3>{t('Search Result')}</h3>
						</div>

						<SearchContent
							searchData={data}
							desktop={true}
							next={state.next}
							searchResponse={state.searchResponse}
							currentPage={state.currentPage}
							newData={newPage}
						/>

						{state.loading && (
							<div
								css={[
									css`
										text-align: center;
										padding: 15px;
										font-size: 18px;
									`,
								]}
							>
								Loading...
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const dispatchProps = { getSearchData };
export default connect(mapStateToProps, dispatchProps)(SearchResult);
