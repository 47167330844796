import * as allTypes from 'actions/types';

const initialState = {};

export const subjects = (state = initialState, action) => {
	const { subject } = action;
	switch (action.type) {
		case allTypes.GET_SUBJECT_UPDATE: {
			return { ...state, allSubjects: subject };
		}
		case allTypes.SET_SUBJECT: {
			return {
				...state,
				grade: subject.grade,
				subject: subject.subjects,
			};
		}
		default:
			return state;
	}
};
