import * as allTypes from './types';

export function getSearchData(
	query,
	currentPage,
	callbackSuccess = null,
	callbackError = null
) {
	return {
		type: allTypes.GET_SEARCH_DATA,
		query,
		currentPage,
		callbackSuccess,
		callbackError,
	};
}
