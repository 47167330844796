import * as allTypes from 'actions/types';

const initialState = {};

export const topics = (state = initialState, action) => {
	const { topic } = action;
	switch (action.type) {
		case allTypes.SET_TOPIC: {
			return { ...state, allTopics: topic, items: topic.items };
		}
		default:
			return state;
	}
};
