import { getEndPoint, getApiToken } from 'config/Config';
import { httpService } from 'services/HttpService';
import OleNepalHttpService from 'services/OleNepalHttpService';

class Chapter {
	static getService(path) {
		const completePath = getEndPoint() + path;
		if (getApiToken()) {
			return OleNepalHttpService.get(completePath);
		}
		/**
		 * If no headers is required
		 */
		return httpService.request('get', null, completePath);
	}

	static getChapter(gradeId, subjectId) {
		const path = `/api/v1/grade/${gradeId}/subject/${subjectId}/chapter/list`;
		return Chapter.getService(path);
	}
}

export default Chapter;
