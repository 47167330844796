import { all } from 'redux-saga/effects';

import gradeSaga from './grade';
import subjectSaga from './subjects';
import chapterSaga from './chapter';
import topicSaga from './topic';
import searchSaga from './search';
import videoSaga from './video';

export default function* rootSaga() {
	yield all([
		gradeSaga(),
		subjectSaga(),
		chapterSaga(),
		topicSaga(),
		searchSaga(),
		videoSaga(),
	]);
}
